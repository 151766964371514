import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import EllipticalButton from "./EllipticalButton";
import TextField from '@mui/material/TextField';
import { Box } from "@mui/material";

const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "default_service", // Replace with your EmailJS Service ID
        "template_hw4shjs", // Replace with your EmailJS Template ID
        form.current,
        "GZ-BAmevqOlRpZTEG"
      )
      .then(
        (result) => {
          alert("Message sent successfully!");
        },
        (error) => {
          alert("Failed to send message. Please try again later.");
          console.error("contact-form error: " + error);
        }
      );
  };

  return (
    <form
      ref={form} // Attach ref to the standard form element
      onSubmit={sendEmail}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px", // Space between inputs
        maxWidth: "400px", // Optional: set a max width
      }}
    >
      <TextField id="outlined-basic" label="Name" name="from_name" variant="outlined" />
      <TextField id="outlined-email" label="Email" name="reply_to" variant="outlined" />
      <TextField id="outlined-number" label="Mobile Number" name="phone_number" variant="outlined" />
      <TextField id="outlined-message" label="Message" name="message" variant="outlined" multiline rows={4} />
      <div>
        <EllipticalButton
        type="submit"
        text="Send"
        style={{
          padding: "10px 20px",
          borderRadius: "5px",
          border: "none",
          backgroundColor: "#007BFF",
          color: "#fff",
          cursor: "pointer",
        }}
      />
      </div>
    </form>
  );
};

export default ContactForm;
