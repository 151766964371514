import { Box, Container, Icon, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EllipticalButton from "./EllipticalButton";

interface InfoCardProps {
  topText?: string;
  headerText: any;
  showButton: boolean;
  showDescription?: boolean;
  buttonSx?: object;
  containerSx: object;
  topTextSx?: object;
  headerSx?: object;
  buttonText?: string;
  buttonOnClick?: () => void;
}

function InfoCard({headerText, showButton, showDescription, containerSx, buttonSx, topTextSx, headerSx, topText = "LIVE NOW", buttonText = "Explore", buttonOnClick}: InfoCardProps) {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md")); 
  return (
    <Container sx={containerSx} >
      <Typography
        variant="body2"
        sx={{
          fontFamily: "Darker Grotesque",
          fontWeight: 500,
          color: "black",
          pt: 2,
          textAlign: showDescription ? "left" : "center",
          ...topTextSx
        }}
      >
        {topText}
      </Typography>
      <Typography
        variant="h4"
        sx={{
          fontFamily: "Libre Baskerville, serif",
          color: "#7C4032",
          fontWeight: 300,
          my: 1,
          textAlign: showDescription ? "left" : "center",
          ...headerSx
        }}
      >
        {headerText}
      </Typography>
      {showButton && <EllipticalButton sx={buttonSx} text={buttonText} customOnClick={buttonOnClick}/>}

      {showDescription && (
        <Box>
          <Typography
            fontFamily={"Darker Grotesque"}
            variant={isMdUp?"h6":"subtitle2"}
            color="black"
            textAlign={"left"}
          >
            Explore Agra’s traditional silver payals. We bring you pieces that
            reflect exquisite craftsmanship and heritage.
          </Typography>
        </Box>
      )}
    </Container>
  );
}

export default InfoCard;
