import React, { useState } from "react";
import { Modal, Box, IconButton, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Lightbox = ({ imgSrc, open, onClose, loading, setLoading }: { imgSrc: string|null; open: boolean; onClose: () => void; loading: boolean; setLoading: (loading: boolean) => void }) => {

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="lightbox-modal"
      aria-describedby="lightbox-modal-description"
      BackdropProps={{
        sx: {
          backgroundColor: "rgba(0, 0, 0, 0.9)", // Increase opacity here
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        position="relative"
      >
        <Box position="absolute" top={10} right={10}>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{color: "white"}}/>
          </IconButton>
        </Box>
        {loading && <CircularProgress />}
        {imgSrc && (
          <img
            src={imgSrc}
            alt="Lightbox"
            style={{
              maxHeight: "90%",
              maxWidth: "90%",
              objectFit: "contain",
              display: loading ? "none" : "block",
            }}
            onLoad={handleImageLoad}
          />
        )}
      </Box>
    </Modal>
  );
};

export default Lightbox;
