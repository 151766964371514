import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import EllipticalButton from './EllipticalButton';
import { useNavigate } from "react-router-dom";
import { SocialIconContainer } from './SocialIconContainer';

const pages = ['Home', 'About Us', 'Contact Us'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function NavigationBar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (page: string) => {
    if(pages.includes(page))
      handleNavigation(page);
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleNavigation = (page: string) => {
    navigate(`/${page.toLowerCase().replace(/\s+/g, "-")}`);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <AppBar sx={{ width: "100vw", backgroundColor: "white", left: "0" }}>
      <Container
        maxWidth={false}
        sx={{
          backgroundColor: "white",
          margin: "0px",
          padding: "0px",
          width: "100%",
        }}
      >
        <Toolbar disableGutters sx={{ width: "100%" }}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={{ xs: "space-between", md: "normal" }}
            width={"100%"}
            px={2}
            alignItems={"center"}
          >
            <Typography //RJDX Text
              component={"a"}
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "flex" },
                fontFamily: "Buda, serif",
                fontWeight: 400,
                fontSize: 32,
                fontStyle: "normal",
                color: "black",
              }}
            >
              RJDX
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
              }}
              columnGap={10}
            >
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={() => handleNavigation(page)}
                  sx={{
                    my: 2,
                    color: "black",
                    display: "block",
                    fontFamily: "Darker Grotesque",
                    fontSize: 18,
                  }}
                >
                  {page}
                </Button>
              ))}
              <EllipticalButton
                text="View Catalog"
                width={137}
                sx={{ my: 2 }}
              />
            </Box>{" "}
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon sx={{ color: "black" }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{ display: { xs: "block", md: "none" } }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={() => handleCloseNavMenu(page)}>
                    <Typography sx={{ textAlign: "center" }}>{page}</Typography>
                  </MenuItem>
                ))}
                <MenuItem onClick={() => handleCloseNavMenu("")}>
                  <EllipticalButton text="View Catalog" />
                </MenuItem>
                <MenuItem sx={{ width: "fit-content", justifySelf: "center"}}>
                  <SocialIconContainer />
                </MenuItem>
              </Menu>
            </Box>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <SocialIconContainer />
            </Box>
          </Box>
          {/* <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "Buda",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "black",
              textDecoration: "none",
            }}
          >
            RJDX
          </Typography> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavigationBar;
