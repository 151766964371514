import { Grid, Typography } from "@mui/material";
import { motion } from "motion/react";

const ItemCard = ({
  imgSrc,
  name
}: {
  imgSrc: string;
  name: string;
}) => (
  <motion.button
  whileHover={{ scale: 1.1 }}
  whileTap={{ scale: 0.95 }}
  >
    <div style={{ width: "100%", aspectRatio: "3 / 4"}}>
      <img
        src={imgSrc}
        alt={name}
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
      <Grid container direction="row" height={"10%"} mt={1}>
        <Grid item xs={6} height={"100%"}>
          <Typography
            variant="body1"
            fontFamily={"Darker Grotesque"}
            fontWeight={500}
            color="#7C4032"
            textAlign={"left"}
            height={"100%"}
          >
            {name}
          </Typography>
        </Grid>
      </Grid>
    </div>
  </motion.button>
);

export default ItemCard;