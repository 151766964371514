import { Box, Icon } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from 'react-router-dom';

export default function EllipticalButton({text, width, sx, customOnClick
}: {text: string, width?: number, sx?: any, customOnClick?: () => void;}) {
  const navigate = useNavigate();
  return (
    <Box
      component="button"
      sx={{
        width: width? width:"105px",
        height: "40px",
        borderRadius: "37px",
        backgroundColor: "#E1DCD5",
        border: "none",
        color: "black",
        fontSize: "14px",
        fontFamily: "Darker Grotesque",
        fontWeight: 400,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#6c6c6c",
        },
        ...sx 
      }}
      onClick={customOnClick ? customOnClick : () => {navigate('/catalog'); window.scrollTo({ top: 0, behavior: "smooth" });}}
    >
      {text}{" "}
      <Icon
        sx={{
          color: "black",
          fontSize: "small",
          width: 20,
          height: 20,
          verticalAlign: "middle",
          marginLeft: "5px",
          marginBottom: "5px",
        }}
        component={"a"}
      >
        <ArrowForwardIcon />
      </Icon>
    </Box>
  );
}
