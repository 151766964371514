import { Box, Typography } from "@mui/material";
import SectionHeader from "../components/SectionHeader";
import ContactForm from "../components/ContactForm";
import { ContactCardList } from "../components/ContactCardList";

const ContactUs = () => {
    return (
      <Box width={"100%"} position="relative" my={15}>
        <SectionHeader text="Contact Us" />
        <Typography
          variant="h6"
          marginTop={7}
          fontFamily={"Darker Grotesque"}
          fontWeight={400}
          color="#0A0A0A"
          textAlign={"left"}
          mx={12}
        >
          Please do not hesitate to reach out to us with any question
        </Typography>
        <Box justifyContent={"left"} mx={11} my={5}>
          <ContactForm />
        </Box>

        <ContactCardList />
      </Box>
    );
}

export default ContactUs;