import { Box, Container , Grid, Typography} from "@mui/material"

const PageFooter = () => (
  <Box height={"356px"} width={"100vw"}>
    <Grid
      container
      xs={12}
      sx={{
        backgroundColor: "#7C4032",
        height: "100%",
        width: "100%",
        display: "flex", // Flex container for alignment
        alignItems: "center", // Vertical centering
        justifyContent: "center", // Horizontal centering
      }}
    >
      <Grid item xs={12}>
        <Typography
          textAlign={"center"}
          sx={{
            mr: 2,
            fontFamily: "Buda, serif",
            fontWeight: 300,
            fontSize: 48,
            fontStyle: "normal",
            color: "#E1DCD5",
            mt: 10,
          }}
        >
          RJDX
        </Typography>
      </Grid>

      <Grid
        container
        justifyContent="center"
        sx={{
          my: 2,
        }}
        xs={12}
      >
        <Grid item xs={3} sm={3} md={1}>
          <a href="/home" style={{ textDecoration: "none", color: "#E1DCD5" }}>
            <Typography
              variant="h6"
              fontFamily={"Darker Grotesque"}
              fontWeight={400}
              color="#E1DCD5"
            >
              Home
            </Typography>
          </a>
        </Grid>
        <Grid item xs={3} sm={3} md={1}>
          <a
            href="/about-us"
            style={{ textDecoration: "none", color: "#E1DCD5" }}
          >
            <Typography
              variant="h6"
              fontFamily={"Darker Grotesque"}
              fontWeight={400}
            >
              About Us
            </Typography>
          </a>
        </Grid>
        <Grid item xs={3} sm={3} md={1}>
          <a
            href="/contact-us"
            style={{ textDecoration: "none", color: "#E1DCD5" }}
          >
            <Typography
              variant="h6"
              fontFamily={"Darker Grotesque"}
              fontWeight={400}
            >
              Contact Us
            </Typography>
          </a>
        </Grid>
        <Grid item xs={3} sm={3} md={1}>
          <a
            href="/catalog"
            style={{ textDecoration: "none", color: "#E1DCD5" }}
          >
            <Typography
              variant="h6"
              fontFamily={"Darker Grotesque"}
              fontWeight={400}
            >
              View Catalog
            </Typography>
          </a>
        </Grid>
      </Grid>
      <Grid item>
        <hr style={{ border: "1px solid #E1DCD5", width: "90vw" }} />
      </Grid>

      <Grid item>
        <Typography
          variant="h6"
          fontFamily={"Darker Grotesque"}
          fontWeight={400}
        >
          All rights reserved RJDX payal
        </Typography>
      </Grid>
    </Grid>
  </Box>
);

export default PageFooter;
