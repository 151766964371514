import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { motion } from "motion/react";
import InfoCard from "../components/InfoCard";
import ImageSlider from "../components/ImageSlider";
import EllipticalButton from "../components/EllipticalButton";
import SectionHeader from "../components/SectionHeader";
import { getConfig } from "../commons/getConfig";
import { ConfigFiles } from "../enums";
import GalleryGrid from "../components/GalleryGrid";
import { ContactCardList } from "../components/ContactCardList";

interface GridItem {
  xs: number
}
type GridConfig = {
  [key: string]: GridItem;
};


const Homepage: React.FC = () => {
  const [imageSliderUrls, setImageSliderUrls] = useState<string[]>([]);
  const [imageGalleryUrls, setImageGalleryUrls] = useState<any[]>([]);
  const [gridConfig, setGridConfig] = useState<GridConfig>({});

  const fetchGridConfig = () => {
    const gridConfigPromise = getConfig(ConfigFiles.HOME_PAGE_GRID);
    gridConfigPromise.then((resolvedConfig: any) => {
      console.log(resolvedConfig);
      setGridConfig(resolvedConfig);
    })
  }
  const fetchImages = async () => {
    try {
      const response = await fetch(
        "https://storage.googleapis.com/storage/v1/b/rjdx-client-bucket/o?prefix=homepage/image_slider/ld"
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      const urls = data.items.map(
        (item: any) =>
          `https://storage.googleapis.com/rjdx-client-bucket/${item.name}`
      );

      // Remove the first element (if needed) and set the state
      setImageSliderUrls(urls.slice(1));

      const responseGallery = await fetch(
        "https://storage.googleapis.com/storage/v1/b/rjdx-client-bucket/o?prefix=homepage/image_gallery/images"
      )

      if (!responseGallery.ok) {
        throw new Error(`HTTP error! status: ${responseGallery.status}`);
      }
      console.log("Grid config from fetch Images: ", gridConfig);
      const galleryData = await responseGallery.json();
      const galleryUrls = galleryData.items.map((item: any, index: number) => {
        // Log the index
        index -=1;
        if(index<0) index = 0;
        console.log("processing index: ", index, item);
          return {
            src: `https://storage.googleapis.com/rjdx-client-bucket/${item.name}`,
            xs: gridConfig[index]['xs']
          };
      });

      setImageGalleryUrls(galleryUrls.slice(1));

    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  useEffect(() => {
    fetchGridConfig();
  }, []); // Runs only once after the component mounts

  useEffect(() => {
    fetchImages();
  }, [gridConfig]); // Runs only once after the component mounts

  return (
    <Box width={"100%"} position="relative">
      {/* Intro Section */}
      <Grid
        container
        gridColumn={{ xs: 1, md: 2 }}
        gridRow={{ xs: 2, md: 1 }}
        xs={12}
        height={{ md: "550px", xs: "300px" }}
      >
        <Grid item xs={12} md={6} height={"100%"}>
          <img
            src="https://storage.googleapis.com/rjdx-client-bucket/homepage/landing_images/ld/image_left.jpg"
            alt="image 1"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Grid>
        <Grid item xs={12} md={6} height={"100%"}>
          <img
            src="https://storage.googleapis.com/rjdx-client-bucket/homepage/landing_images/ld/image_right.jpg"
            alt="image 2"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Grid>
      </Grid>

      <Box sx={{ my: { md: 0, xs: 20 } }}></Box>

      <InfoCard
        headerText="Traditional Silver Payal"
        showButton={true}
        containerSx={{
          position: "relative",
          // left: {xs: "12%"},
          transform: {
            md: "translate(0, -200%)",
            xs: "translate(0, -150%)",
          },
          zIndex: 2,
          width: { md: "30%", xs: "90%" },
          height: { md: 184, xs: 200 },
          backgroundColor: "white",
        }}
      />
      {/* Collection Section */}
      <motion.div
        initial={{ opacity: 0.7, y: 100 }}
        whileInView={{ opacity: 1, transition: { duration: 0.2 }, y: 0 }}
        viewport={{ once: true, amount: 0.3 }}
      >
        <Grid
          container
          justifyContent={"space-between"}
          xs={12}
          pl={{ md: 12, xs: 2 }}
        >
          <Grid item md={4.5} xs={11} textAlign={"left"}>
            <Typography
              variant="body1"
              fontFamily={"Darker Grotesque"}
              fontWeight={400}
              color="black"
            >
              COLLECTION 2024
            </Typography>
            <SectionHeader
              text="Explore Agra’s finest silver payals"
              sx={{ textAlign: "left", ml: "0" }}
            />
          </Grid>

          <Grid item xs={11} md={7} my={{ xs: 6, md: 0 }}>
            <ImageSlider image_slider_urls={imageSliderUrls} />
          </Grid>
        </Grid>
      </motion.div>

      <Box sx={{ my: { md: 20, xs: 2 } }}></Box>

      {/* Our Values Section*/}
      {/* <Grid>
        <Typography
          variant="h3"
          fontFamily={"Libre Baskerville, serif"}
          fontWeight={400}
          color="#7C4032"
          textAlign={"center"}
          mx={12}
        >
          Our Values
        </Typography>
        <Grid container columns={3} xs={12}>
          <Grid item xs={4}>
            <CircleIcon
              icon={
                <VerifiedIcon />
              }
            />
          </Grid>
          <Grid item></Grid>
          <Grid item></Grid>
        </Grid>
      </Grid> */}

      {/* Photo Grid */}
      <motion.div
        initial={{ opacity: 0.7, y: 100 }}
        whileInView={{ opacity: 1, transition: { duration: 0.2 }, y: 0 }}
        viewport={{ once: true, amount: 0.3 }}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            pb: "56.25%",
          }}
        >
          <Box
            sx={{
              pl: { md: 12, xs: 2 },
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <GalleryGrid
              images={imageGalleryUrls}
              maxRows={2}
              rowHeight={380}
            />
          </Box>
        </Box>
        <EllipticalButton text="View Catalog" width={130} />
      </motion.div>

      <Grid my={{ md: 20, xs: 10 }}></Grid>

      {/* Contact Us seciton */}

      <motion.div
        initial={{ opacity: 0.7, y: 100 }}
        whileInView={{ opacity: 1, transition: { duration: 0.2 }, y: 0 }}
        viewport={{ once: true, amount: 0.3 }}
      >
        <SectionHeader text="Contact Us" />
        <Typography
          variant="h6"
          marginTop={{ md: 5, xs: 2 }}
          fontFamily={"Darker Grotesque"}
          fontWeight={500}
          color="#0A0A0A"
          textAlign={{ md: "left", xs: "center" }}
          mx={{ md: 12, xs: 2 }}
        >
          Please do not hesitate to reach out to us with any question
        </Typography>
        <ContactCardList />
      </motion.div>
    </Box>
  );
};

export default Homepage;
