import React, { Component } from "react";
import Slider from "react-slick";
import imgleft from "../Assets/homepage1.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTheme, useMediaQuery, Box } from "@mui/material";

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <ArrowForwardIosIcon className={className} style={{ ...style, display: "block" }}
    onClick={onClick} color="primary" sx={{color: "black", "&:hover": {color: "black"}}}/>
  );
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <ArrowBackIosIcon
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
      color="primary"
      sx={{ color: "black", "&:hover": { color: "black" } }}
    />
  );
}

interface SimpleSliderProps {
  image_slider_urls: string[];
}

export default function SimpleSlider({ image_slider_urls }: SimpleSliderProps) {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md")); // Matches screens >= 'md'
  const centerPadding = isMdUp ? "150px" : "0px"; // Set padding dynamically based on screen size

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: centerPadding,
    speed: 500,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  console.log("image_slider_urllength: ", image_slider_urls.length);
  image_slider_urls.map((url, index) => {
    console.log("Image url: " + url);
  });

  return (
    <Box
      className="slider-container"
      width={"100%"}
      height={{md: "416px", xs: "260px"}}

    >
      <Slider {...settings}>
        {image_slider_urls.map((url, index) => (
          <div>
            <img
              src={url}
              alt={`image ${index}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                paddingInline: "10px",
                marginBottom: "10px"
              }}
            />
          </div>
        ))}
      </Slider>
    </Box>
  );
}
