import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

const CategorySectionHeader = ({
  headerText,
  descText,
  sx,
}: {
  headerText: string;
  descText: string;
  sx?:object;
}) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md")); 

  return (
    <Box sx={sx}>
      <Typography
        variant={isMdUp?"h4":"h6"}
        fontFamily={"Libre Baskerville, serif"}
        fontWeight={500}
        color="#7C4032"
        textAlign={"left"}
      >
        {headerText}
      </Typography>
      <Box my={1} />
      <Typography
        variant={isMdUp?"h6": "subtitle1"}
        fontFamily={"Darker Grotesque"}
        fontWeight={400}
        color="black"
        textAlign={"left"}
      >
        {descText}
      </Typography>
    </Box>
  )
};

export default CategorySectionHeader;
