import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react"

const SectionHeader = ({text, sx}: {text: string, sx?: object}) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md")); 
  return(
    <Typography
      variant={isMdUp ? "h3" : "h4"}
      fontFamily={"Libre Baskerville, serif"}
      fontWeight={400}
      color="#7C4032"
      textAlign={{xs: "center", md: "left"}}
      ml={{md: 12}}
      sx={sx}
    >
      {text}
    </Typography>
  );
}

export default SectionHeader; 