import React from "react"

export const GetProductImages = async () => {
  try{
    const responseProducts = await fetch(
          "https://storage.googleapis.com/storage/v1/b/rjdx-client-bucket/o?prefix=catalog_page/product_gallery/ld"
        )

        if (!responseProducts.ok) {
          throw new Error(`HTTP error! status: ${responseProducts.status}`);
        }
        const galleryData = await responseProducts.json();
        const imageUrls = galleryData.items.map((item: any, index: number) => {
          // Log the index
          index -=1;
          if(index<0) index = 0;
          console.log("processing index: ", index, item);
            return {
              imgSrc: `https://storage.googleapis.com/rjdx-client-bucket/${item.name}`,
              name: `Product ${index+1}`
            };
        });
        
        return imageUrls.slice(1);
    } catch(error){
        console.error("Error fetching images:", error);
    }

}

export const GetSliderImages = async() => {
  try {
      const response = await fetch(
        "https://storage.googleapis.com/storage/v1/b/rjdx-client-bucket/o?prefix=homepage/image_slider/ld"
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      const urls = data.items.map(
        (item: any) =>
          `https://storage.googleapis.com/rjdx-client-bucket/${item.name}`
      );

      // Remove the first element (if needed) and set the state
      return urls.slice(1);
    } catch(error){
        console.error("Error fetching images:", error);
    }
}

export const GetLandingImages = async() => {
  
}