import { Box, Grid, Typography } from "@mui/material";

const ContactCard = ({
  heading,
  icon,
  description,
}: {
  heading: string;
  icon?: any;
  description: any;
}) => (
  <Box width={"100%"}>
    <Grid
      container
      xs={12}
      direction="column"
      width={"100%"}
      my={{ xs: 3, md: 0 }}
    >
      <Grid item xs={12} textAlign={{ md: "left", xs: "center" }}>
        <Typography
          variant="h6"
          color="#7C4032"
          fontFamily={"Darker Grotesque"}
          fontWeight={500}
        >
          {heading}
        </Typography>
      </Grid>

      <Grid item my={1} />

      <Grid item xs={12}>
        <Typography
          variant="body1"
          color="black"
          fontFamily={"Darker Grotesque"}
          fontWeight={500}
          display={"flex"}
          justifyContent={{ xs: "center", md: "left" }}
        >
          {icon && <Box>{icon}</Box>}
          <Box mx={0.5} />
          {description}
        </Typography>
      </Grid>

      <Grid item my={2} display={{md: "none"}}/>
      <Grid item xs={12} display={{ md: "none" }}>
        <hr style={{ border: "1px solid #7C4032", width: "80%", margin: "0 auto" }} />
      </Grid>
    </Grid>
  </Box>
);

export default ContactCard;