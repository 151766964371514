export async function getConfig(fileName) {
  // Initialize the Google Cloud Storage client
  try {
    // Replace with your file's public URL
    const url = `https://storage.googleapis.com/rjdx-client-bucket/${fileName}`;
    
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error('Error fetching JSON file:', error);
  }
  };
