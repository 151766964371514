import { Grid } from "@mui/material";
import ContactCard from "./ContactCard";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { SocialIconContainer } from "./SocialIconContainer";

export const ContactCardList = () => (
  <Grid
    container
    xs={10}
    mx={{ md: 12, xs: 4 }}
    my={7}
    direction={{ md: "row", xs: "column" }}
    justifyContent={"space-evenly"}
  >
    <Grid item xs={10} md={2}>
      <ContactCard
        heading="OUR LOCATION"
        icon={<LocationOnIcon style={{ color: "#7C4032" }} />}
        description="136-B,  Chaubeji ka Phatak,  Kinari Bazaar,  Agra,  India"
      />
    </Grid>
    <Grid item xs={10} md={2}>
      <ContactCard
        heading="PHONE NUMBER"
        icon={<PhoneIcon style={{ color: "#7C4032" }} />}
        description="+91 96349 72600"
      />
    </Grid>
    <Grid item xs={10} md={2}>
      <ContactCard
        heading="EMAIL"
        icon={<EmailIcon style={{ color: "#7C4032" }} />}
        description="rajambah@gmail.com"
      />
    </Grid>
    <Grid xs={12} md={2}>
      <ContactCard
        heading="OUR SOCIALS"
        description={
          <SocialIconContainer />
        }
      />
    </Grid>
  </Grid>
);