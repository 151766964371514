import { Grid } from "@mui/material";
import React from "react";

// Define the structure for each image
interface ImageProps {
  src: string;
  xs: number
}

// Define the structure for the gallery component props
interface GalleryProps {
  images: ImageProps[];
  maxRows: number, 
  rowHeight: number
}

// Create the GalleryGrid component
const GalleryGrid: React.FC<GalleryProps> = ({ images, maxRows, rowHeight }) => {
  return (
    <Grid container
      xs={12}
      spacing={1}
      height={"100%"}
      style={{
        alignItems: "center",
        width: "100%"
      }}
    >
      {images.map((image, index) => (
        <Grid item
          key={index}
          xs = {image.xs}
          md = {image.xs-1}
          height={`${100 / maxRows}%`}
        >
          <img
            src={image.src}
            alt={`Image ${index + 4}`}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
            }}
            // style={{
            //   width: "100%",
            //   height: "100%",
            //   objectFit: "cover",
            // }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default GalleryGrid;