import { Box, Grid, Pagination, PaginationItem, useMediaQuery, useTheme } from "@mui/material";
import imgleft from "../Assets/homepage1.png";
import InfoCard from "../components/InfoCard";
import CategorySectionHeader from "../components/CategorySectionHeader";
import CategoryBox from "../components/CategoryBox";
import { Gallery } from "react-grid-gallery";
import ItemCard from "../components/ItemCard";
import ItemGallery from "../components/ItemGallery";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Widgets } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { GetProductImages } from "../ImageController";
import { useNavigate } from "react-router-dom";
import { motion } from "motion/react";

interface ProductImage {
  imgSrc: string, 
  name: string
}

const IMAGES_PER_PAGE = { xs: 8, md: 12 }; // Number of images per page


const CatalogPage: React.FC = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const [productImages, setProductImages] = useState<ProductImage[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false); // New loading state
  useEffect(() => {
    GetProductImages().then((data) => {
      if (data) {
        setProductImages(data);
      }
    });
  }, []);

  const handlePageChange = async (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setLoading(true);
    await new Promise(resolve => setTimeout(resolve, 500));
    setCurrentPage(value);
    setLoading(false);
  };

  // Calculate images for the current page
  const images_per_page = isMdUp ? IMAGES_PER_PAGE.md : IMAGES_PER_PAGE.xs;
  const startIndex = (currentPage - 1) * images_per_page;
  const endIndex = startIndex + images_per_page;
  const paginatedImages = productImages.slice(startIndex, endIndex);

  return (
      <Box
        width={"100%"}
        position="relative"
        className={loading ? "fade-out" : ""}
      >
        {/* Header Section */}
        <Box height={{ md: "350px", xs: "200px" }}>
          <img
            src={imgleft}
            alt="image 2"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Box>

        <InfoCard
          headerText="Catalog Collection 2024"
          showDescription={true}
          showButton={false}
          containerSx={{
            position: "absolute",
            transform: {
              md: "translate(20%, -130%)",
              xs: "translate(5%, -30%)",
            },
            zIndex: 2,
            width: { md: "30%", xs: "90%" },
            height: { md: 184, xs: 200 },
            backgroundColor: { md: "white", xs: "#F5F5F5" },
          }}
        />

        <Box my={{ md: 15, xs: 25 }} />

        {/* Categories List */}

        {/* <CategorySectionHeader
        headerText="Categories"
        descText="Explore payals from our five categories"
      />

      <Grid
        container
        xs={10}
        mx={12}
        my={7}
        direction={"row"}
        justifyContent={"space-between"}
      >
        <CategoryBox headerText="KUNDAN PAYAL" descText="38 Pieces Left" />
        <CategoryBox headerText="KUNDAN PAYAL" descText="38 Pieces Left" />
        <CategoryBox headerText="KUNDAN PAYAL" descText="38 Pieces Left" />
        <CategoryBox headerText="KUNDAN PAYAL" descText="38 Pieces Left" />
        <CategoryBox headerText="KUNDAN PAYAL" descText="38 Pieces Left" />
      </Grid> */}

        {/* <Box my={20} /> */}

        {/* Product Gallery */}

        <CategorySectionHeader
          headerText="Collections 2024"
          descText="All Categories"
          sx={{ marginLeft: { xs: 3, md: 15 } }}
        />
        <Box my={2} />
        <hr style={{ border: "1px solid #E1DCD5", width: "90vw" }} />
        <Box my={4} />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: loading ? 0 : 1 }}
          transition={{ duration: 0.5 }}
        >
          <ItemGallery
            items={paginatedImages}
            maxColumns={{ xs: 2, sm: 2, md: 3, lg: 4 }}
            maxRows={4}
            rowGap={0}
            columnGap={{ md: 8, xs: 2 }}
          />
        </motion.div>

        <Box my={5}></Box>

        <Pagination
          sx={{
            px: { md: 12, xs: 0 },
            width: "100%",
            backgroundColor: "#E1DCD5",
            justifyItems: "center",
          }}
          onChange={handlePageChange}
          count={Math.ceil(productImages.length / images_per_page)} // Total pages
          page={currentPage} // Controlled page number
          renderItem={(item) => (
            <PaginationItem
              sx={{ color: "#7C4032" }}
              slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              {...item}
            />
          )}
        />

        <Box my={15} />
        {/* Reach Out  */}
        <InfoCard
          headerText={
            <>
              Like what you see here?
              <br />
              Let’s take this forward!
            </>
          }
          showButton={true}
          containerSx={{
            transform: "translate(0%, 0)",
            width: { md: 838, xs: "90%" },
            height: { md: 184, xs: 320 },
            backgroundColor: "#E1DCD5",
            paddingTop: "1px",
          }}
          buttonSx={{
            position: "relative",
            border: "1px solid #7C4032",
            width: "140px",
            my: { md: 0, xs: 2 },
            top: { md: "-40%" },
            left: { md: "40%" },
          }}
          headerSx={{
            textAlign: { md: "left", xs: "center" },
          }}
          topTextSx={{
            textAlign: { md: "left", xs: "center" },
            fontSize: "20px",
            fontWeight: 400,
          }}
          topText="CONTACT US"
          buttonText="Reach Out To Us"
          buttonOnClick={() => {
            navigate("/contact-us");
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        />

        <Box my={10} />
      </Box>
  );
}

export default CatalogPage;