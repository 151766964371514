import { Grid } from "@mui/material";
import ItemCard from "./ItemCard";
import Lightbox from "./Lightbox";
import { useState } from "react";

const ItemGallery = ({
  maxRows,
  maxColumns,
  rowGap,
  columnGap,
  items,
}: {
  maxRows: number;
  maxColumns: any;
  rowGap: number;
  columnGap: any;
  items: any[];
}) => {

  const [openLightbox, setOpenLightbox] = useState<boolean>(false);
  const [activeImage, setActiveImage] = useState<string|null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleOnItemClick = (imgSrc: string) => {
    const modifiedImgSrc = imgSrc.replace('/ld', '');
    setActiveImage(modifiedImgSrc);
    setOpenLightbox(true);
    setLoading(true);
  }

  const closeLightbox = () => {
    setOpenLightbox(false);
    setActiveImage(null);
  };

  return <>
    <Grid container spacing={columnGap} px={{md: 12, xs: 2}} xs={12} style={{width: "100%"}}>
      {items.map((item, index) => (
        <Grid
          item
          xs={12 / maxColumns.xs}
          sm={12 / maxColumns.sm}
          md={12 / maxColumns.md}
          lg={12 / maxColumns.lg}
          style={{
            width: "100%",
            cursor: "pointer"
          }}
          key={index}
          onClick = {() => handleOnItemClick(item.imgSrc)}
          
        >
          <ItemCard imgSrc={item.imgSrc} name={item.name} />
        </Grid>
      ))}
    </Grid>

    <Lightbox
      imgSrc={activeImage}
      onClose={closeLightbox} // Function to close the Lightbox
      open={openLightbox}
      loading={loading}
      setLoading={setLoading}
    />
    
  </>;
};

export default ItemGallery;
