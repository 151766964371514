import { Box, Grid, Typography } from "@mui/material";
import SectionHeader from "../components/SectionHeader";
import imgleft from "../Assets/homepage1.png";

export const AboutUs = () => 
    <Box width={"100%"} position="relative" my={15}>
        <SectionHeader text="About Us" />
        <Grid container textAlign={"left"} xs={12} px={12} columnGap={10}>
        <Grid
            item
            xs={5}
            fontFamily={"Darker Grotesque"}
            fontWeight={400}
            color="#0A0A0A"
        >
            <Typography variant="body2" marginTop={7}>
            We bring together tradition and artisanal perfection to create
            silver payals for every occasion. We strive to deliver timeless
            pieces using the finest quality silver, blending classic and
            contemporary designs.
            </Typography>
            <Typography variant="body2" mt={5}>
            <p style={{ color: "#7C4032", fontSize: "20px" }}>2018</p>
            <p>Founded</p>
            </Typography>
            <Typography variant="body2" mt={5}>
            <p style={{ color: "#7C4032", fontSize: "20px" }}>2022</p>
            <p>First Stall in AIJE Agra</p>
            </Typography>
            <Typography variant="body2" mt={5}>
            <p style={{ color: "#7C4032", fontSize: "20px" }}>2023</p>
            <p>
                Exhibited collection in Silver Show of India (SSI) Bangalore
            </p>
            </Typography>
        </Grid>
        <Grid item xs={0.5} />
        <Grid item xs={4.5} style={{ backgroundColor: "blue" }} mt={7}>
            <img
            src={imgleft}
            alt="image 1"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
        </Grid>
        </Grid>
        <Grid my={25}></Grid>
    </Box>;
