import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Homepage from './pages/Homepage';
import NavigationBar from './components/NavigationBar';
import PageFooter from './components/PageFooter';
import CatalogPage from './pages/CatalogPage';
import ContactUs from './pages/ContactUs';
import { AboutUs } from './pages/AboutUs';

function App() {
  return (
    <Router>
      <div className="App">
        <NavigationBar />
        <Routes>
          {/* Route for Homepage */}
          <Route path="/home" element={<Homepage />} />

          {/* Route for CatalogPage */}
          <Route path="/catalog" element={<CatalogPage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element= {<AboutUs />} />

          {/* Redirect from root path to /home */}
          <Route path="/" element={<Navigate to="/home" />} />
        </Routes>
        <PageFooter />
      </div>
    </Router>
  );
}

export default App;