
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export const SocialIconContainer = () => <div
  style={{
    color: "#7C4032",
    display: "flex",
    justifyContent: "space-between",
    gap: 10
    
  }}
>
  <a
    href="https://www.facebook.com/rjdxpayal/"
    target="_blank"
    rel="noopener noreferrer"
  >
    <FacebookIcon />
  </a>
  <a
    href="https://wa.me/+919634972600"
    target="_blank"
    rel="noopener noreferrer"
  >
    <WhatsAppIcon />
  </a>
</div>;
